import React from 'react';
import useUser from './hooks/useUser';
import Login from './Login';


function Welcome() {

    const { user } = useUser();

    return (<>
        <div className="bg-dark-dpool text-white center jumbotron">
            <h1>DPooL Swimming & Wellness</h1>
        </div>

        {user?.username ? <>
            <div className="bg-dark-dpool text-white jumbotron">
                <p>Здравей, {user?.firstName}</p>
            </div>
        </> :
            <Login />
        }
    </>);

}

export default Welcome;